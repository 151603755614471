import React from "react";
import "./App.scss";
import { v4 as uuidv4 } from "uuid";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./components/Header/Header";
import Main from "./pages/Main/Main";
import ProjectPage from "./pages/ProjectPage/ProjectPage";
import ScrollToTop from "./components/ScrollToTop";

import firebaseConfig from "./config";
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const reactNativeIcon = "https://img.icons8.com/officel/40/000000/react.png";
const sassIcon = "https://img.icons8.com/color/48/000000/sass.png";
const firebaseIcon = "https://img.icons8.com/color/48/000000/firebase.png";
const htmlIcon = "https://img.icons8.com/color/48/000000/html-5.png";
const nodeIcon = "https://img.icons8.com/windows/32/000000/node-js.png";

function App() {
  const projects = [
    {
      id: 5,
      title: "Expense Tracker",
      pitch:
        "An expense tracking app to exercise my coding skills post bootcamp",
      date: "Sept 2020",
      duration: "2 days",
      role: "Design & code",
      techStack: "React Js and Firebase",
      techIcon: [
        { icon: reactNativeIcon, text: "React Js" },
        { icon: firebaseIcon, text: "firebase" },
      ],
      experience:
        "I really wanted to experiment with react hooks and the useContext api. After completing the project I realized that it would be nice for the app to be more personalized so I added firebase auth and firebase firestore so that you can actually save your expenses.",
      description:
        "While at Brainstation, we learnt so much, so quickly. Every week had a new project with clearly defined deliverables, designs and deadlines.As a recent Brainstation graduate, this is something that I miss! So I've made it a goal to keep that learning train moving by publicly learning, designing, coding, deploying and posting a new project every two weeks! Here's the first of many. I made an expense tracking app with React Js using only hooks and react context sprinkled in there. For the back-end, I used Firebase for auth and database.Please feel free to use it, share it with your friends and please please please send me feedback :)",
      hero: "https://i.imgur.com/CMLOHrc.png?1",
      demo: "https://oliver-levy-expense-tracker.netlify.app/",
      demoBtn: "view site",
      img: [
        {
          original: "https://i.imgur.com/CMLOHrc.png?1",
          originalClass: "gallery__img",
        },
       
      ],
    },
    {
      id: 1,
      title: "make",
      pitch:
        "A video platform for how-to videos with extra functionality to keep users on track to completing their projects.",
      date: "July 2020",
      duration: "10 days",
      role: "Design & code",
      techStack: "React Native, Firebase",
      techIcon: [
        { icon: reactNativeIcon, text: "React Native" },
        { icon: firebaseIcon, text: "firebase" },
      ],
      description:
        "Make, is an app for learning how to do things! Make will be your go-to for many personal projects. The app will only host quality how-to videos that show difficulty of project, required tools and materials, step by step instructions, user feedback, cost of project and other useful information that’s required to complete your project.",
      hero: "https://i.imgur.com/DqCsjSI.png",
      problem:
        "There’s a plethora of how-to videos on many major platforms. These platforms were never designed for more than just watching a video. The quality of how-to videos varies and sometimes never shows the user what they’re really looking for. Another pain point is sourcing the right materials to complete your project.",
      userProfile:
        "Anyone who has ever googled how to do something. We’ve all been there! Some people are visual and enjoy watching a video and some like step by step instructions. Make combines those two aspects and adds the convenience of having all of your project resources in one place.",
      demo: "https://www.youtube.com/watch?v=ZFEtApWwBK8&t",
      demoBtn: "watch demo",
      img: [
        {
          original: "https://i.imgur.com/DqCsjSI.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/oC9r9jG.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/yWJl0Gn.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/UyE5OgW.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/q0i3Hpm.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/SXlsdVL.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/AMEAYmP.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/6jJXkRo.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/oPnqO7h.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/CqXSQ1Y.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/aqHWXdS.png",
          originalClass: "gallery__img",
        },
      ],
    },
    {
      id: 2,
      title: "Brainflix",
      pitch: "A Brainstation project to teach us how to make an Express app",
      date: "June 2020",
      duration: "7 days",
      role: "code",
      techStack: "React Js, Express, Sass",
      techIcon: [
        { icon: reactNativeIcon, text: "React JS" },
        { icon: nodeIcon, text: "Node / Express" },
        { icon: sassIcon, text: "SASS" },
      ],
      description:
        "Brainflix is a project given by Brainstation to teach us how build client side and server side applications using React Js, Node and Express. The app has a bunch of functionality but to save on storage, doesn't play video and doesn't allow the user to upload video. Go and check it out and leave a comment on the landing page video!",
      hero: "https://i.imgur.com/mGjhcTa.png",
      demo: "https://bs-brainflix-oliver-levy.netlify.app/",
      demoBtn: "view site",
      img: [
        {
          original: "https://i.imgur.com/mGjhcTa.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/FzyC9ag.png",
          originalClass: "gallery__img",
        },
      ],
    },
    {
      id: 3,
      title: "Pair Programming | Billionaires",
      pitch: "One day of pair programming with Lucas Bernadini",
      date: "July 2020",
      duration: "7 hours",
      role: "Design & code",
      techStack: "React Js, SASS",
      techIcon: [
        { icon: reactNativeIcon, text: "React JS" },
        { icon: sassIcon, text: "SASS" },
      ],
      experience:
        "This project put our pair programming skills to the test! We were given 7 hours to come up with a concept, find a suitable API, design, code, and present. We worked very well together and both found the experience amazing!",
      description:
        "The app compares the input salary to the top 20 billionaires in the world and tells you how many years you would have to work to make as much as that billionaire.",
      hero: "https://i.imgur.com/xO311tm.png",
      demo: "https://amazing-hopper-3192b9.netlify.app/",
      demoBtn: "view site",
      img: [
        {
          original: "https://i.imgur.com/xO311tm.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/XlVtiWX.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/AKkVw17.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/MUqZawO.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/nyHHRvg.png",
          originalClass: "gallery__img",
        },
      ],
    },
    {
      id: 4,
      title: "Rick and Morty",
      pitch:
        "An exploration of the Rick and Morty open API with Aditia Sharma and Kushal Yagnik.",
      date: "June 2020",
      duration: "7 hours",
      role: "Design & code",
      techStack: "HTML, SASS",
      techIcon: [
        { icon: htmlIcon, text: "HTML" },
        { icon: sassIcon, text: "SASS" },
      ],
      experience:
        "After learning about API's, we were tasked with making a unique project to test our new skill. We were givin 7 hours to complete this project. In that short timeframe, we came up with a concept, found a suitable API, designed the site, coded and presented.",
      description:
        "This app simply displays information on characters found in the TV show, Rick and Morty.",
      hero: "https://i.imgur.com/wfi0D7C.png",
      demo: "https://thirsty-jepsen-e68bb1.netlify.app/",
      demoBtn: "view site",
      img: [
        {
          original: "https://i.imgur.com/wfi0D7C.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/adp0AhO.png",
          originalClass: "gallery__img",
        },
        {
          original: "https://i.imgur.com/IIcWcZk.png",
          originalClass: "gallery__img",
        },
      ],
    },
  ];

  return (
    <Router>
      <ScrollToTop />
      <Header />

      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <Main {...props} data={projects} />}
        />
        <Route
          path="/project/:id"
          render={(props) => <ProjectPage {...props} data={projects} />}
        />
      </Switch>
    </Router>
  );
}
export default App;
