import React, { useState } from "react";
import "./Nav.scss";

import Modal from "react-modal";
Modal.setAppElement("#root");

export default function Nav() {
  const [isOpen, setIsOpen] = useState(() => false);

  function toggleNav() {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }

  return (
    <div className="nav">
      <button className="nav__btn" onClick={toggleNav}>
        &#x02261;
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        className="nav__modal"
        overlayClassName="nav__overlay"
        closeTimeoutMS={2000}
      >
        <button onClick={toggleNav} className="nav__btn">
          &#x02260;
        </button>
        <a
          href="https://www.linkedin.com/in/oliver-levy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h2>linkedIn</h2>
        </a>
        <a
          href="https://github.com/OliverLevy"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h2>github</h2>
        </a>
        <a
          href="mailto:oliver.levy.ol@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h2>email</h2>
        </a>
        <a
          href="https://drive.google.com/file/d/13SrzMzLZ-tzDI4In8nOIzLLkG7aA2ogQ/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h2>resume</h2>
        </a>
      </Modal>
    </div>
  );
}
