import React from "react";
import "./Info.scss";
import HeadShot from "../../assets/oliver-levy-webDev.jpg";

export default function Info() {
  return (
    <div className="info">
      <div className="info__hello-container">
        <div>
          <h1 className="info__hello">Hello,</h1>
          <h1 className="info__title">
            My name is <span className="accent">Oli</span>
          </h1>
        </div>

        <a
          href="https://www.linkedin.com/in/oliver-levy/"
          target="_blank"
          rel="noopener noreferrer"
          className="info__head-shot-container"
        >
          <img
            src={HeadShot}
            alt="Oliver Levy Headshot"
            className="info__head-shot"
          />
          {/* <h2>it me</h2> */}
          linkedin
        </a>
      </div>
      <p className="info__about-me">
        I’m a full-stack web developer who <br /> designed furniture &amp;
        lighting for{" "}
        <a
          href="https://www.gabriel-scott.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Gabriel-Scott
        </a>
        , <br /> repaired bikes for many years, <br />
        loves his three beautiful dogs, <br /> Bill, Franklin and Chester.
      </p>
    </div>
  );
}
