import React from "react";
import "./Header.scss";
import Typical from "react-typical";
import Interval from "../Counter/Counter";
import Nav from '../Nav/Nav'

export default function Header() {
  return (
    <div className='header'>
      <div className="typical">
        <h4>hello world,</h4>
        <h4>
          Oliver{" "}
          <Typical
            steps={[
              "is a designer.",
              2000,
              "is a web developer.",
              2000,
              "is always looking to learn.",
              2000,
              "has three dogs",
              200,
              "has three beautiful dogs.",
              2000,
              "is looking for a job!",
              2000,
            ]}
            loop={Infinity}
            wrapper="i"
          />
        </h4>
        <h4>
          time spent giving me a chance:<Interval />
        </h4>
      </div>
      <Nav/>
    </div>
  );
}
