import React from "react";
import "./ProjectPage.scss";
import BackIcon from "../../assets/back-icon.png";

import Gallery from "../../components/Gallery/Gallery";

export default function ProjectPage(props) {
  const data = props.data;
  const id = props.match.params.id;

  const output = data.filter((item) => item.id === Number(id));

  return output.map((item) => {
    return (
      <div key={item.id} className="project__bg">
        <button onClick={() => props.history.goBack()} className="goBack">
          <img src={BackIcon} alt="go back" className="goBack__icon" />
          <h2>back</h2>
        </button>
        <div className="project-page">
          <h1 className="accent project__title">{item.title}</h1>
          <p className="project__pitch">{item.pitch}</p>

          <div className="project_details-container">
            <div className="project__details">
              <p className="project__details-label">Date</p>
              <p>{item.date}</p>
            </div>
            <div className="project__details">
              <p className="project__details-label">Duration</p>
              <p>{item.duration}</p>
            </div>
            <div className="project__details">
              <p className="project__details-label">Role</p>
              <p>{item.role}</p>
            </div>
            <div className="project__details">
              <p className="project__details-label">Tech Stack</p>
              <p>{item.techStack}</p>
            </div>
            <a href={item.demo} target="_blank" rel="noopener noreferrer">
              <button className="cta">{item.demoBtn}</button>
            </a>
          </div>
        </div>

        <Gallery img={item.img} />

        <div className="project-page">
          {item.description && (
            <div>
              <h2>Description</h2>
              <p className="project__description">{item.description}</p>
            </div>
          )}

          {item.experience && (
            <div>
              <h2>Learning Experience</h2>
              <p className="project__description">{item.experience}</p>
            </div>
          )}

          {item.problem && (
            <div>
              <h2>Problem</h2>
              <p className="project__description">{item.problem}</p>
            </div>
          )}

          {item.userProfile && (
            <>
              <h2>User profile</h2>
              <p className="project__description">{item.userProfile}</p>
            </>
          )}
        </div>
      </div>
    );
  });
}
