import { useEffect, useState } from "react";

const dynaDate = (num) => {
  if (num < 60) {
    return `${num}s`;
  } else if (num < 3600) {
    return `${Math.floor(num / 60)}m 🙏 `;
  } else {
    return `${Math.floor(num / 60 / 60)}h`;
  }
};

const Interval = () => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return dynaDate(seconds);
};

export default Interval;
