const firebaseConfig = {
  apiKey: "AIzaSyD1QgdH0eXupnyzgJ-Xt1uJkJEy85_k5Xg",
  authDomain: "oliverlevy.firebaseapp.com",
  databaseURL: "https://oliverlevy.firebaseio.com",
  projectId: "oliverlevy",
  storageBucket: "oliverlevy.appspot.com",
  messagingSenderId: "83590000818",
  appId: "1:83590000818:web:e76196efcab15d13e299ab",
  measurementId: "G-JRNBNVZEWN"
};

export default firebaseConfig