import React from "react";
import "./Gallery.scss";
import ImageGallery from "react-image-gallery";

import Slider from "react-slick";

// const settings = {
//   infinite: true,
//   lazyLoad: false,
//   showThumbnails: false,
//   showPlayButton: false,
//   showFullscreenButton: false,
//   responsive: [
//     {
//       breakpoint: 1200,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 2,
//         initialSlide: 2,
//       },
//     },
//     {
//       breakpoint: 768,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       },
//     },
//   ],
// };

// export default function Gallery({ img }) {
//   return (
//     <div className="gallery">
//       <ImageGallery items={img} {...settings} />
//     </div>
//   );
// }

export default class Gallery extends React.Component {
  displayImages = () => {
    const data = this.props.img;
    return data.map((item) => {
      return (
        <div key={item.original} className="testing2">
          <img src={item.original} alt="" className="gallery__img" />
        </div>
      );
    });
  };

  render() {
    const settings = {
      className: "gallery__slider",
      infinite: true,
      speed: 500,
      dots: true,
      accessibility: true,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 2800,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="gallery">
        <Slider {...settings}>{this.displayImages()}</Slider>
      </div>
    );
  }
}
