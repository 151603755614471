import React from "react";
import "./Main.scss";

import Info from "../../components/Info/Info";
import ProjectCard from "../../components/ProjectCard/ProjectCard";

export default function Main({ data, brainflix }) {
  return (
    <div className="main">
      <Info />
      <h2 className="main__projects-title">projects</h2>

      <ProjectCard data={data} />
    </div>
  );
}

//projects should be sticky
//make image modal on click
//details should be smaller fonts and smaller line height spacing
