import React from "react";
import "./ProjectCard.scss";
import { Link } from "react-router-dom";

export default function ProjectCard({ data }) {
  return (
    <div className='main__projects'>
      {data.map((item) => {
        return (
          <div className="project" key={item.id}>
            <div className="project__hero">
              <Link to={`/project/${item.id}`} className="project__hero-link">
                <img
                  src={item.hero}
                  alt={`hero for ${item.title} project`}
                  className="project__hero-img"
                />
              </Link>
            </div>
            <div className="project__info-container">
              <div className="project__details-date">
                <div className="project__title-container">
                  <h2 className="accent project__title">{item.title}</h2>
                  <p className="project__pitch">{item.pitch}</p>
                </div>
                <div className="project__details">
                  <p>{item.date}</p>
                  <p>{item.duration}</p>
                </div>
              </div>
              <div className="project__details-icons">
                {item.techIcon.map((item) => {
                  return (
                    <div className="project__details-icons-container">
                      <img
                        src={item.icon}
                        alt=""
                        className="project__tech-icon"
                      />
                      <h4 className="project__details-icon-text">
                        {item.text}
                      </h4>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
